import React from 'react';

import { Wrapper, Flex, IconLink } from '../primitives';
import { Discord, Ethereum, Twitter } from '../assets';
import config from '../core/configuration';
import { NavPosition } from '../styling/NavigationStyles';

const Navigation = () => {
  return (
    <NavPosition>
      <Wrapper noMargin>
        <Flex marginTop='32px' spaceBetween>
          <h3>ARTificial</h3>
          <Flex width='max-content' gap='16px'>
            <IconLink
              discord
              onClick={() => window.open(config.discord, '_blank')}
            >
              <Discord size='21' />
            </IconLink>
            <IconLink
              twitter
              href={config.twitter}
              target='_blank'
              rel='noopener norefferer'
            >
              <Twitter size='21' />
            </IconLink>
            <IconLink href={config.opensea} target='_blank'>
              <Ethereum size='21' />
            </IconLink>
          </Flex>
        </Flex>
      </Wrapper>
    </NavPosition>
  );
};

export default Navigation;
