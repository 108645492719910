import styled from 'styled-components';

import backgroundImage from '../assets/header.png';

const HeaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 998;
`;
const ContentWidth = styled.div`
  max-width: 732px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media only screen and (max-width: 700px) {
    max-width: 90%;
  }
`;

const SpaceOne = styled.div`
  margin-bottom: 32px;
  margin-top: 21px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 64px;
    margin-top: 24px;
  }
`;

export { HeaderWrapper, ContentWidth, SpaceOne };
