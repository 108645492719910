import styled, { css } from 'styled-components';

const Flex = styled.div`
  display: flex;
  position: relative;
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '' }) => height};
  margin-right: ${({ marginRight = '0px' }) => marginRight};
  margin-left: ${({ marginLeft = '0px' }) => marginLeft};
  margin-top: ${({ marginTop = '0px' }) => marginTop};
  margin-bottom: ${({ marginBottom = '0px' }) => marginBottom};
  padding: ${({ padding = '0px' }) => padding};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  height: ${({ fullHeight }) => (fullHeight ? '100%' : '')};
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  gap: ${({ gap = '' }) => gap};

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}

  ${({ flexStart }) =>
    flexStart &&
    css`
      justify-content: flex-start !important;
      align-items: left;
    `}

    ${({ flexEnd }) =>
    flexEnd &&
    css`
      justify-content: flex-end;
    `}

    ${({ mobile }) =>
    mobile &&
    css`
      @media screen and (max-width: 800px) {
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: left;
      }
    `}
`;

export default Flex;
