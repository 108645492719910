import styled from 'styled-components';

import background from '../assets/middle.png';

const Background = styled.div`
  width: 100vw;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const ButtonMaxWidth = styled.div`
  max-width: 675px;
  margin-top: 32px;
  width: 100%;
`;

export { Background, ButtonMaxWidth };
