import React from 'react';

import { Wrapper, Grid, Flex, Button } from '../../primitives';
import SectionHeader from '../SectionHeader';
import DemoBox from '../cards/DemoBox';

import AuctionCard from '../cards/AuctionCard';
import temp from '../../assets/TemporaryItems';
import Slider from '../Slider';

const CurrentAuction = ({ demo }) => {
  return (
    <Wrapper white fluid>
      <Wrapper noMargin padding='0px'>
        <SectionHeader
          title='current auctions'
          subtitle='gem package'
          href='https://opensea.io/ARTifcial'
        />
        <Flex marginBottom='32px'>
          <Slider length={temp.length} height='410px'>
            {demo && <DemoBox light />}

            {temp.map((nft) => (
              <AuctionCard
                image={nft.image}
                id={nft.id}
                name={nft.name}
                key={nft.id}
                price={nft.price}
              />
            ))}
          </Slider>
        </Flex>
        <Button line>Check out all auctions</Button>
      </Wrapper>
    </Wrapper>
  );
};

export default CurrentAuction;
