import styled from 'styled-components'


const TextHolder = styled.div`
    width: 100%;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export {TextHolder}