const theme = {
    background: '#171717',
    backgroundLight: '#1C1C1D',
    primary: '#802ED1',
    primaryHover: '#9A3CF8',
    text: '#C0C4DB',
    heading: '#ffffff',
    border: '#CFCFCF'
  };
  
  export default theme;
  