import styled from 'styled-components';

const NavPosition = styled.div`
  z-index: 999;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
`;

export { NavPosition };
