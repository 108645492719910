import styled from 'styled-components';

import background from '../assets/footer.png';

const FooterWrapper = styled.div`
  width: 100vw;
  padding-top: 100px;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (min-width: 1920px) {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TextMaxWidth = styled.div`
  max-width: 730px;
`;

export { FooterWrapper, TextMaxWidth };
