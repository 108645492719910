import styled, { css } from 'styled-components';

const IconLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.heading};
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.text};
  }
  ${({ discord }) =>
    discord &&
    css`
      &:hover {
        color: #5865f2;
      }
    `}
  ${({ twitter }) =>
    twitter &&
    css`
      &:hover {
        color: #1da1f2;
      }
    `}
`;

export default IconLink;
