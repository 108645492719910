import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
      ${styledNormalize}
    
    * {
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        z-index: 1;
        font-size: 10px;
        @media screen and (max-width: 800px) {
            font-size: 8px;
        }
    }

    html {
        font-family: 'Kanit', sans-serif;
        color: ${({ theme }) => theme.heading};
        background-color: ${({ theme }) => theme.background};
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        overflow-x: hidden;
    }

    html, body, #app {
        height: 100%;
        width: 100%;
    }

    h1 {
        margin: 0px;
        padding: 0px;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
        font-size: 4.8rem;
        line-height: 5.6rem;
        cursor: default ;

        -webkit-user-select: none; /* Safari */        
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
    }

    h2 {
        margin: 0px;
        padding: 0px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        font-size: 4rem;
        line-height: 4rem;
    }

    h3 {
        margin: 0px;
        margin-top: 3px;
        padding: 0px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        font-size: 2.1rem;
        margin-bottom: 6px;
        line-height: 2.1rem;
    }

    h4 {
        margin: 0px;
        padding: 0px;
        font-weight: normal;
        text-transform: uppercase;
        text-align: left;
        font-size: 2.1rem;
        line-height: 2.1rem;

    }
    h5 {
        margin: 0px;
        padding: 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 2.4rem;
        text-transform: uppercase;
    }

    h6 {
        margin: 0px;
        padding: 0px;
        font-family: Kanit;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        white-space: nowrap;
    }

    p {
        margin: 0px;
        padding: 0px;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.1rem;
        text-align: center;
        color: ${({ theme }) => theme.text};
    }

    b {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        white-space: nowrap;
    }
`;

export default GlobalStyle;
