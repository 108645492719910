import tempOne from './temp1.png'
import tempTwo from './temp2.png'
import tempThree from './temp3.png'

const temp = [
    {
        image: tempOne,
        url: '',
        name: 'Super Crystal',
        id: '001',
        price: '2.00'
    },
    {
        image: tempTwo,
        url: '',
        name: 'Super Emerald',
        id: '002',
        price: '2.00'
    },
    {
        image: tempThree,
        url: '',
        name: 'Super Ether',
        id: '003',
        price: '2.00'
    },
    {
        image: tempOne,
        url: '',
        name: 'Super Crystal',
        id: '006',
        price: '2.00'
    },
    {
        image: tempThree,
        url: '',
        name: 'Super Emerald',
        id: '007',
        price: '2.00'
    },
    {
        image: tempOne,
        url: '',
        name: 'Super Crystal',
        id: '008'
    },
    {
        image: tempTwo,
        url: '',
        name: 'Super Emerald',
        id: '009',
        price: '2.00'
    },
    {
        image: tempThree,
        url: '',
        name: 'Super Ether',
        id: '010',
        price: '2.00'
    },
    {
        image: tempThree,
        url: '',
        name: 'Super Ether',
        id: '011',
        price: '2.00'
    },
]

export default temp