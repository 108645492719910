import React from 'react';

const Ether = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 21 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.6'>
        <path
          opacity='0.6'
          d='M10.9988 7.76543L5 10.6977L10.9988 14.5069L16.9953 10.6977L10.9988 7.76543Z'
          fill='currentColor'
        />
      </g>
      <g opacity='0.45'>
        <path
          opacity='0.45'
          d='M5 10.6977L10.9988 14.5069V0L5 10.6977Z'
          fill='currentColor'
        />
      </g>
      <g opacity='0.8'>
        <path
          opacity='0.8'
          d='M10.9988 0V14.5069L16.9952 10.6977L10.9988 0Z'
          fill='currentColor'
        />
      </g>
      <g opacity='0.45'>
        <path
          opacity='0.45'
          d='M5 11.9166L10.9988 21V15.7259L5 11.9166Z'
          fill='currentColor'
        />
      </g>
      <g opacity='0.8'>
        <path
          opacity='0.8'
          d='M10.9988 15.7259V21L17 11.9166L10.9988 15.7259Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default Ether;
