import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { RightArrow } from '../assets';
import Flex from './Flex';
const ButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  background: ${({ theme }) => theme.primary};
  border-radius: 6px;
  color: white;
  border: none;
  transition: background 0.2s;
  cursor: pointer;
  line-break: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 999;
  text-decoration: none;

  &:hover {
    background: ${({ theme }) => theme.primaryHover};
  }
  ${({ line }) =>
    line &&
    css`
      width: 100%;
      color: ${({ theme }) => theme.primary};
      background: none;
      border: 1.5px solid ${({ theme }) => theme.primary};
      &:hover {
        color: ${({ theme }) => theme.heading};
        background: ${({ theme }) => theme.primary};
      }
    `}
  ${({ white }) =>
    white &&
    css`
      width: 100%;
      color: ${({ theme }) => theme.heading};
      background: none;
      border: 1.5px solid ${({ theme }) => theme.heading};
      &:hover {
        color: ${({ theme }) => theme.background};
        background: ${({ theme }) => theme.heading};
      }
    `}
    ${({ width }) =>
    width &&
    css`
      width: 100%;
    `}
`;

const Button = ({ children, func, arrow, line, white, width }) => {
  const [hover, setHover] = useState(false);
  return (
    <ButtonWrapper
      onClick={func}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      line={line}
      white={white}
      width={width}
    >
      <b>{children}</b>
      {arrow && (
        <Flex marginLeft={hover ? '16px' : '8px'}>
          <RightArrow size='18' />
        </Flex>
      )}
    </ButtonWrapper>
  );
};

export default Button;
