import React from 'react'
import styled from 'styled-components'

import {Flex, Image} from '../../primitives/'
const CardWrapper = styled.div`
    cursor: pointer;
`

const Card = ({image, id, name}) => {
    return (
       <CardWrapper>
           <Image src={image}/>
           <Flex spaceBetween marginTop='12px' gap='14px'>
                <b>{name}</b>
                <h6>#{id}</h6>
           </Flex>
       </CardWrapper>
        )
    }

export default Card
