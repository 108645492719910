import styled from 'styled-components';
import React from 'react';

const DemoBoxWrapper = styled.div`
  backdrop-filter: blur(4px);
  opacity: 0.9;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: ${({ theme, light }) =>
    light ? theme.heading : theme.background}99;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DemoBox = ({ light }) => <DemoBoxWrapper light={light}></DemoBoxWrapper>;

export default DemoBox;
