import React from 'react';

const RightArrow = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.7594 9.91936C20.7592 9.91911 20.759 9.91882 20.7587 9.91858L16.4724 5.65296C16.1513 5.33341 15.6319 5.3346 15.3122 5.65575C14.9926 5.97686 14.9939 6.49624 15.315 6.81584L18.1928 9.6797H0.820312C0.367254 9.6797 0 10.047 0 10.5C0 10.9531 0.367254 11.3203 0.820312 11.3203H18.1928L15.315 14.1842C14.9939 14.5038 14.9927 15.0232 15.3123 15.3443C15.6319 15.6655 16.1513 15.6666 16.4724 15.3471L20.7587 11.0814C20.759 11.0812 20.7592 11.0809 20.7594 11.0807C21.0807 10.76 21.0797 10.239 20.7594 9.91936Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default RightArrow;
