import React, { useState } from 'react';

import { Wrapper, Flex, Button } from '../../primitives';
import temp from '../../assets/TemporaryItems';
import TopFiveCard from '../cards/TopFiveCard';
import { Background, ButtonMaxWidth } from '../../styling/topFiveStyles';

const TopFive = ({ demo }) => {
  let placement = 0;
  return (
    <Background>
      <Wrapper padding>
        <Flex column>
          <h1>Top five sales</h1>

          <Flex column marginTop='48px' gap='24px'>
            {temp.slice(4).map((nft) => {
              placement++;
              return (
                <TopFiveCard
                  image={nft.image}
                  name={nft.name}
                  id={nft.id}
                  price={nft.price}
                  placement={placement}
                />
              );
            })}
          </Flex>
          <ButtonMaxWidth>
            <a href='https://opensea.io/ARTifcial'>
              <Button white>Check out all sales</Button>
            </a>
          </ButtonMaxWidth>
        </Flex>
      </Wrapper>
    </Background>
  );
};

export default TopFive;
