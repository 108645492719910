import styled, { css } from 'styled-components';

const Grid = styled.div`
    width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1;
  column-gap: 48px;
  overflow:auto;
  padding-bottom: ${({padding}) => padding ? '40px' : ''};

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
}
@media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
}
`;

export default Grid