import React from 'react';
import { Wrapper, Grid, Flex } from '../../primitives';
import Card from '../cards/Card';
import DemoBox from '../cards/DemoBox';
import temp from '../../assets/TemporaryItems';
import Slider from '../Slider';

import { TextHolder } from '../../styling/comingSoonStyles';

const CominSoon = ({ demo }) => {
  return (
    <Wrapper fluid light noMargin>
      <Wrapper noMargin>
        <TextHolder>
          <h1>Coming Soon</h1>
          <h3>30-09-2021</h3>
        </TextHolder>
        <Slider length={temp.length}>
          {demo && <DemoBox />}

          {temp.map((nft) => (
            <Card image={nft.image} id={nft.id} name={nft.name} key={nft.id} />
          ))}
        </Slider>
      </Wrapper>
    </Wrapper>
  );
};

export default CominSoon;
