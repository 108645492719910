import React from 'react'
import styled from 'styled-components'

import {Flex, Image, Button} from '../../primitives'

import {Ether} from '../../assets'

const AuctionCardWrapper = styled.div`
    border: 2px solid ${({theme}) => theme.border};
    border-radius: 2px; 
    padding: 12px;
`


const AuctionCard = ({image, name, id, price}) => {
    return (
        <AuctionCardWrapper>
            <Flex marginBottom='21px'>
            <Image src={image}/>

                </Flex>
            <Flex spaceBetween marginBottom='21px'> 
                <div>
                    <b>{name}</b>
                    <h6>#{id}</h6>
                </div>
                <Flex width='max-content' >
                    <Flex marginRight='8px'>
                        <Ether size='21'/>
                    </Flex>
                    <b>{price}</b>
                    </Flex>
            </Flex>
            <Button width>VIEW ON OPEANSEA</Button>
        </AuctionCardWrapper>
    )
}

export default AuctionCard
