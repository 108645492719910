import React, { useState } from 'react';
import styled from 'styled-components';
import { RightArrow } from '../assets';
import Flex from './Flex';

const LinkWrapper = styled.a`
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  color: currentColor;
  text-decoration: none;

  @media only screen and (max-width: 800px) {
    margin-right: auto;
    color: ${({ theme }) => theme.text};
  }
`;

const ArrowLink = ({ href, cta }) => {
  const [hover, setHover] = useState(false);
  return (
    <LinkWrapper
      href={href}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Flex start pointer>
        <h6>{cta}</h6>
        <Flex marginLeft={hover ? '24px' : '12px'}>
          <RightArrow size='18' />
        </Flex>
      </Flex>
    </LinkWrapper>
  );
};

export default ArrowLink;
