import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const SliderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${({ height = '280px' }) => height};
`;

const InnerSlider = styled.div`
  position: absolute;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(${({ length }) => length}, 1fr);
  gap: 40px;
  pointer-events: none;
  transition: 0s ease;
  z-index: 1;
  white-space: nowrap;
  left: ${({ offset }) => offset};
`;

const Slider = ({ children, length, height }) => {
  const [pressed, setPressed] = useState(false);
  const [start, setStart] = useState(0);
  const [x, setX] = useState(0);

  const sliderRef = useRef(null);
  const outerSlider = useRef();

  useEffect(() => {
    outerSlider.current.addEventListener('mousemove', (e) => {
      if (pressed) {
        setX(e.offsetX);
      }
    });
    return;
  }, [start]);

  const slideHandler = (e) => {
    setPressed(true);
    setStart(e.nativeEvent.offsetX - sliderRef.current.offsetLeft);
  };

  const disableSlider = () => {
    setPressed(false);
  };

  return (
    <SliderWrapper
      // onMouseDown={slideHandler}
      // onMouseUp={disableSlider}
      height={height}
      ref={outerSlider}
    >
      <InnerSlider
        length={length}
        offset={`${x - start}px`}
        ref={sliderRef}
        onMouseLeave={disableSlider}
      >
        {children}
      </InnerSlider>
    </SliderWrapper>
  );
};

export default Slider;
