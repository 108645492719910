import React from 'react';
import styled from 'styled-components';
import { Flex, Divider, ArrowLink, Grid } from '../primitives';

const TextWrapper = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  width: max-content;
  @media only screen and (max-width: 700px) {
    align-items: start;
    margin-right: auto;
    margin-bottom: 3px;
  }
`;

const SectionHeader = ({
  title = 'title',
  subtitle = 'sub',
  href,
  cta = 'SEE FULL COLLECTION',
}) => {
  return (
    <Flex spaceBetween marginBottom='21px' padding='40px 0px' mobile>
      <TextWrapper width='max-content'>
        <h3>{title}</h3>
        <Divider />
        <h4>{subtitle}</h4>
      </TextWrapper>
      <ArrowLink href={href} cta={cta} />
    </Flex>
  );
};

export default SectionHeader;
