import React from 'react'
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet"; 

import favicon from '../assets/favicon.png'

import theme from '../styling/theme'
import GlobalStyle from '../styling/globalStyle'
import {Wrapper, Grid} from '../primitives'

import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

const Layout = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
        <GlobalStyle />

                  <Helmet>
                  <meta name="theme-color" content="#802ED1" />
                    <title>ARTificial</title>
                    <link rel='preconnect' href='https://fonts.googleapis.com' />
                    <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
                    <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <link
          href='https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&display=swap'
          rel='stylesheet'
        />
        </ Helmet>
                <Navigation/>
                    {children}
                    <Footer/>
        </ThemeProvider>
    )
}

export default Layout