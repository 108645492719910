import React from 'react';

const Discord = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.685 15.897H13.3793L12.8685 14.2432C12.9443 14.3092 17.25 18 17.25 18V1.85625C17.199 0.85275 16.335 0 15.264 0L2.68875 0.00225C1.6185 0.00225 0.75 0.8565 0.75 1.86V14.04C0.75 15.0982 1.617 15.897 2.685 15.897ZM10.596 4.26225L10.5712 4.27125L10.5802 4.26225H10.596ZM4.87275 5.214C6.2475 4.2135 7.52175 4.2615 7.52175 4.2615L7.6245 4.36275C5.94225 4.764 5.1795 5.5155 5.1795 5.5155C5.2575 5.499 8.65425 3.53925 12.7703 5.565C12.7703 5.565 12.006 4.86225 10.4272 4.41225L10.5667 4.275C10.785 4.27575 11.94 4.31625 13.176 5.22C13.176 5.22 14.559 7.5825 14.559 10.485C14.5133 10.4295 13.701 11.7345 11.6108 11.7795C11.6108 11.7795 11.2568 11.379 11.0048 11.0295C12.2273 10.6785 12.6847 9.9765 12.6847 9.9765C10.305 11.475 8.21925 11.241 5.724 10.2285C5.70075 10.2285 5.69025 10.218 5.67825 10.206V10.2015C5.66625 10.1903 5.65575 10.179 5.6325 10.179H5.5875C5.4345 10.0785 5.3325 10.029 5.3325 10.029C5.3325 10.029 5.78925 10.731 6.963 11.082C6.65475 11.4338 6.3495 11.8335 6.3495 11.8335C4.26 11.784 3.498 10.479 3.498 10.479C3.498 7.572 4.87275 5.214 4.87275 5.214Z'
        fill='currentColor'
      />
      <path
        d='M10.731 9.57825C11.2642 9.57825 11.6985 9.12825 11.6985 8.57325C11.6985 8.02201 11.2665 7.57201 10.731 7.57201V7.57426C10.2 7.57426 9.76499 8.02276 9.76349 8.57775C9.76349 9.12825 10.1977 9.57825 10.731 9.57825Z'
        fill='currentColor'
      />
      <path
        d='M7.26749 9.57825C7.80073 9.57825 8.23498 9.12825 8.23498 8.57325C8.23498 8.02201 7.80374 7.57201 7.27049 7.57201L7.26749 7.57426C6.73424 7.57426 6.29999 8.02276 6.29999 8.57775C6.29999 9.12825 6.73424 9.57825 7.26749 9.57825Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Discord;
