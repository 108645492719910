import React from 'react';

const Ethereum = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.00647 13.7227L4.01099 11.2838L9.00194 18L9.75175 16.9909V13.3577L9.00647 13.7227Z'
        fill='currentColor'
      />
      <path
        d='M9.00192 0L3.26953 10.2858H14.7345L9.00192 0Z'
        fill='currentColor'
      />
      <path
        d='M9.00186 7.49254L8.97934 7.48183L3.27002 10.2943H3.2751L8.98442 13.1068L9.00694 13.0961L9.75168 12.7316V7.85976L9.00186 7.49254Z'
        fill='currentColor'
      />
      <path
        d='M9.00648 13.7227L9.00195 18L13.9965 11.2793L9.00648 13.7227Z'
        fill='currentColor'
      />
      <path
        d='M14.7237 10.2943L9.00189 7.49254L8.97937 7.48183L8.98445 13.1068L9.00697 13.0961L14.7288 10.2943H14.7237Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Ethereum;
