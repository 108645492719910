import React from 'react';
import { FooterWrapper, TextMaxWidth } from '../styling/footerStyles';
import { Wrapper, Button, Flex } from '../primitives';
import config from '../configuration';
const Footer = () => {
  return (
    <FooterWrapper>
      <Wrapper noMargin>
        <Flex marginBottom='48px' noMargin>
          <TextMaxWidth>
            <h1>START YOUR OWN COLLECTION TODAY</h1>
          </TextMaxWidth>
        </Flex>
        <Flex>
          <a href='https://opensea.io/ARTifcial'>
            <Button arrow href={config.opensea}>
              Browse on opensea
            </Button>
          </a>
        </Flex>
        <Flex marginTop='100px'>
          <p>Copyright 2021-2022. All rights reserved</p>
        </Flex>
      </Wrapper>
    </FooterWrapper>
  );
};

export default Footer;
