import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: ${({ fluid }) => (fluid ? '100vw' : '1600px')};
  padding: 0px 21px;
  margin: 0px auto;
  background: ${({ light, theme }) => (light ? theme.backgroundLight : '')};
  padding-bottom: 100px;
  padding: ${({ padding }) => padding};
  margin-bottom: ${({ noMargin }) => (noMargin ? ' 0px' : '152px')};
  ${({ white }) =>
    white &&
    css`
      background: ${({ theme }) => theme.heading};
      color: ${({ theme }) => theme.background};
    `}
`;

export default Wrapper