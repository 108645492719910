import React from 'react';

import Layout from '../core/Layout';
import {
  Header,
  LatestCollection,
  ComingSoon,
  CurrentAuction,
  TopFive,
  AllCollections,
} from '../components/landing';
import config from '../configuration';

const demo = config.demo;

const index = () => {
  return (
    <Layout>
      <Header />
      <LatestCollection demo={demo} />
      <ComingSoon demo={demo} />
      <CurrentAuction demo={demo} />
      <TopFive demo={demo} />
      <AllCollections demo={demo} />
    </Layout>
  );
};

export default index;
