import styled from 'styled-components';

const Divider = styled.div`
  width: 2px;
  height: 16px;
  background: currentColor;
  margin: 0px 16px;
`;

export default Divider;
