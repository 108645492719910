import React from 'react';

import {
  HeaderWrapper,
  ContentWidth,
  SpaceOne,
} from '../../styling/HeaderStyles';
import { Flex, Button } from '../../primitives';

import config from '../../configuration';

const Header = () => {
  return (
    <HeaderWrapper>
      <Flex height='100%'>
        <ContentWidth>
          <h1>A unique new digital collectable NFT Project</h1>
          <SpaceOne>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              feugiat condimentum diam, imperdiet varius nulla pretium in.
            </p>
          </SpaceOne>
          <a href='https://opensea.io/ARTifcial'>
            <Button arrow onClick={() => alert('test')}>
              Explore on open sea
            </Button>
          </a>
        </ContentWidth>
      </Flex>
    </HeaderWrapper>
  );
};

export default Header;
