import React from 'react';

import { Wrapper, Grid, Flex } from '../../primitives';
import SectionHeader from '../SectionHeader';
import Card from '../cards/Card';
import DemoBox from '../cards/DemoBox';

import temp from '../../assets/TemporaryItems';
import Slider from '../Slider';

const AllCollections = ({ demo }) => {
  return (
    <Wrapper fluid noMargin>
      <Wrapper noMargin>
        <SectionHeader
          title='all collections'
          subtitle='gem package'
          href='https://opensea.io/ARTifcial'
        />
        <Slider length={temp.length}>
          {demo && <DemoBox />}

          {temp.map((nft) => (
            <Card image={nft.image} id={nft.id} name={nft.name} key={nft.id} />
          ))}
        </Slider>
      </Wrapper>
    </Wrapper>
  );
};

export default AllCollections;
