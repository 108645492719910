import React from 'react';

import styled from 'styled-components';

import { getTopFive } from '../../libs/getNFT';

import { Ether } from '../../assets';
import { ArrowLink, Flex } from '../../primitives';

console.log(getTopFive);

const TopFiveCardWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 675px;
  display: flex;
  align-items: top;
  border-radius: 6px;
  background: ${({ theme }) => theme.heading};
  padding: 14px;
  color: ${({ theme }) => theme.background};
`;

const NFTimage = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 4px;
  margin-right: 14px;
`;

const TextContainer = styled.div`
  position: absolute;
  left: 14px;
  top: 4px;
  @media only screen and (max-width: 700px) {
    left: 0px;
  }
`;

const LinkWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const NumberWrapper = styled.div`
  position: absolute;
  right: 14px;
  bottom: 14px;
`;

const TopFiveCard = ({ image, name, id, price, placement }) => {
  return (
    <TopFiveCardWrapper>
      <NFTimage src={image} />
      <Flex spaceBetween>
        <TextContainer>
          <div>
            <h3>{name}</h3>
            <h4>#{id}</h4>
          </div>
          <Flex marginTop='32px' flexStart>
            <Flex marginRight='4px' width='max-content'>
              <Ether size='24' />
            </Flex>
            <h4>{price}</h4>
          </Flex>
        </TextContainer>
        <Flex width='max-content'></Flex>
      </Flex>
      <NumberWrapper>
        <h2>#{placement}</h2>
      </NumberWrapper>
      <LinkWrapper>
        <ArrowLink cta='checkout on opensea' />
      </LinkWrapper>
    </TopFiveCardWrapper>
  );
};

export default TopFiveCard;
